import React, { createRef, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap';

function FileInput({
  onChange,
  isMultiple,
  label,
  buttonLabel,
  errorText,
  acceptFormats,
  onSubmit,
  id = 'file',
}) {
  const [fileName, setFileName] = useState(null);
  const input = createRef();

  const changeHandler = e => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];

      setFileName(file.name);

      onChange && onChange(file);
    } else {
      setFileName(null);
    }
  };

  const submitHandler = () => {
    const files = input.current.files;
    onSubmit(files);
  };

  return (
    <Row className="mb-3">
      <Col>
        <FormGroup>
          {label && <FormLabel>{label}</FormLabel>}
          <Row>
            <Col sm={3}>
              <Button onClick={submitHandler} style={{ minWidth: '100px' }}>
                {buttonLabel}
              </Button>
            </Col>
            <Col sm={9} className="d-flex align-items-center">
              <label htmlFor={`${id}-upload`} className="file-upload-label">
                Choose File
              </label>
              <span className="file-upload-subtitle">
                {fileName ?? 'No file chosen'}
              </span>
              <input
                id={`${id}-upload`}
                type="file"
                accept={acceptFormats}
                onChange={changeHandler}
                ref={input}
                multiple={isMultiple}
              />

              {errorText && <span className="text-danger">{errorText}</span>}
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default FileInput;

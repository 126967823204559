export const PointTypes = {
  HELI: 'HELI',
  GYM: 'GYM',
  HOME: 'HOME',
  POOL: 'POOL',
  SOFA: 'SOFA',
  ARROW_UP: 'ARROW_UP',
  ARROW_LEFT: 'ARROW_LEFT',
  ARROW_RIGHT: 'ARROW_RIGHT',
  PREVIEW: 'PREVIEW',
};

export const PointNames = {
  [PointTypes.HELI]: 'HELI',
  [PointTypes.GYM]: 'GYM',
  [PointTypes.HOME]: 'HOME',
  [PointTypes.POOL]: 'POOL',
  [PointTypes.SOFA]: 'SOFA',
  [PointTypes.ARROW_UP]: 'UP',
  [PointTypes.ARROW_LEFT]: 'LEFT',
  [PointTypes.ARROW_RIGHT]: 'RIGHT',
  [PointTypes.PREVIEW]: 'PREVIEW',
};

export const HotspotTypes = {
  IMAGE: 'image',
  TEXT: 'text',
  IFRAME: 'iframe',
  GALLERY: 'gallery',
  FURNITURE: 'furniture',
  POINT: 'point',
};

export const HotspotNames = {
  [HotspotTypes.IMAGE]: 'Image',
  [HotspotTypes.TEXT]: 'Text',
  [HotspotTypes.IFRAME]: 'Iframe',
  [HotspotTypes.GALLERY]: 'Gallery',
  [HotspotTypes.FURNITURE]: 'Furniture',
  [HotspotTypes.POINT]: 'Point',
};

export const CustomThemes = {
  LUNAS: 'LUNAS',
};
export const SuccessMessages = {
  PROPERTY_DELETING: 'Property has been deleted successfully',
  MULTILOCATION_RENAMING: 'Multilocation has been renamed',
};

export const WarningMessages = {
  PROPERTY_DELETING:
    'Attention! When deleting a property, all locations created in it are deleted',
};

export const ErrorMessages = {
  FORBIDDEN_CHARACTERS: 'Field contains forbidden characters',
  REQUIRED_FIELD: 'Required field',
  PROPERTY_EXISTS: 'Property with this name already exists',
  STH_WENT_WRONG: 'Something went wrong ',
  WRONG_SUBSCRIPTION_ID_FORMAT: 'e.g.: sub_1ORc9XEqx4URDeO2kP1E9KmC',
  WRONG_QUICK_BOOKS_INVOICE_ID_FORMAT: 'Max 5 digits',
};

export const DEFAULT_CENTER_LAT = 37.3284498660516;
export const DEFAULT_CENTER_LNG = -121.88659256964227;

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { DATA_PROP_ENUM, UNITS_PROP_NAME_ENUM } from './consts';
import EditorTable from './EditorTable';
import { useDispatch, useSelector } from 'react-redux';
import { createToast, getRecognizedSvgData } from 'redux/actions';
import { uploadXLSXFile } from 'shared/utils/Explorer3dApi';
import * as actionTypes from '../../../redux/actions/types';

const defaultAlertData = {
  isShown: false,
  bodyText: '',
  closeBtnText: '',
  handleClickCloseBtn: () => null,
};

const EditorCSV = ({ closeEditor, opened, explorer3dId }) => {
  const [modalTab, setModalTab] = useState(DATA_PROP_ENUM.Units);
  const [alertData, setAlertData] = useState(defaultAlertData);
  const [isCellsDataChanged, setIsCellsDataChanged] = useState(false);
  const [tablesData, setTablesData] = useState(null);
  const isDataCSVLoading = useSelector(
    state => state.editorCSV.isDataCSVLoading
  );
  const isPopupExpanded = useSelector(state => state.editorCSV.isPopupExpanded);
  const dataCSV = useSelector(state => state.editorCSV.dataCSV);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tablesData && dataCSV) {
      setTablesData(() => structuredClone(dataCSV));
    }
  }, [dataCSV, tablesData]);

  const handleClickModalTab = useCallback(newTab => {
    setModalTab(newTab);
  }, []);

  const handleClickCloseTable = useCallback(() => {
    const handleCloseTableWithoutSaving = () => {
      setAlertData(() => defaultAlertData);
      closeEditor();
      setIsCellsDataChanged(false);
      dispatch({
        type: actionTypes.SET_DATA_CSV,
        payload: null,
      });
      setTablesData(null);
    };

    if (isCellsDataChanged) {
      setAlertData(() => ({
        isShown: true,
        bodyText:
          'You have unsaved changes. Do you want to leave without saving?',
        closeBtnText: 'Yes, close without saving',
        handleClickCloseBtn: handleCloseTableWithoutSaving,
      }));
    } else {
      handleCloseTableWithoutSaving();
    }
  }, [closeEditor, dispatch, isCellsDataChanged]);

  const handleCloseAlert = useCallback(() => {
    setAlertData(() => defaultAlertData);
  }, []);

  const handleSetIsCellsDataChanged = useCallback(() => {
    if (!isCellsDataChanged) {
      setIsCellsDataChanged(true);
    }
  }, [isCellsDataChanged]);

  const handleClickSaveChanges = useCallback(async () => {
    dispatch({
      type: actionTypes.SET_IS_DATA_CSV_LOADING,
      isDataCSVLoading: true,
    });

    try {
      await uploadXLSXFile(explorer3dId, tablesData);
      setIsCellsDataChanged(false);

      dispatch({
        type: actionTypes.SET_IS_DATA_CSV_LOADING,
        isDataCSVLoading: false,
      });
      dispatch(createToast('SUCCESS', 'Changes saved'));
    } catch (error) {
      dispatch({
        type: actionTypes.SET_IS_DATA_CSV_LOADING,
        isDataCSVLoading: false,
      });
      dispatch(
        createToast('ERROR', "Something happened and changes weren't saved")
      );
    }
  }, [dispatch, explorer3dId, tablesData]);

  const handleClickRecognizeSVG = useCallback(async () => {
    const recognizeSvg = async () => {
      const recognizedData = await dispatch(getRecognizedSvgData(explorer3dId));

      setTablesData(prevTableData => {
        const newTableData = prevTableData?.[DATA_PROP_ENUM.Units].map(unit => {
          const currentExternalUnitNumber =
            unit[UNITS_PROP_NAME_ENUM.ExternalUnitNumber];
          const recognizedDataItem =
            recognizedData[unit[UNITS_PROP_NAME_ENUM.ExternalId]];

          if (!currentExternalUnitNumber && recognizedDataItem) {
            unit[UNITS_PROP_NAME_ENUM.ExternalUnitNumber] = recognizedDataItem;

            const buildingId = unit[UNITS_PROP_NAME_ENUM.BuildingId];
            const sectionId = unit[UNITS_PROP_NAME_ENUM.SectionId];
            const floorNumber = unit[UNITS_PROP_NAME_ENUM.FloorNumber];

            unit[UNITS_PROP_NAME_ENUM.InternalUnitId] =
              `B${buildingId}S${sectionId}F${floorNumber}U${recognizedDataItem}`;

            return unit;
          }

          return unit;
        });

        return { ...prevTableData, [DATA_PROP_ENUM.Units]: newTableData };
      });

      setIsCellsDataChanged(true);
      setAlertData(() => defaultAlertData);

      dispatch({
        type: actionTypes.SET_IS_DATA_CSV_LOADING,
        isDataCSVLoading: false,
      });
      dispatch(
        createToast(
          'SUCCESS',
          'Recognized SVG data was successfully downloaded'
        )
      );
    };

    if (isCellsDataChanged) {
      setAlertData(() => ({
        isShown: true,
        bodyText:
          'You have unsaved changes. Do you want to start recognizing without saving?',
        closeBtnText: 'Yes, start without saving',
        handleClickCloseBtn: recognizeSvg,
      }));
    } else {
      await recognizeSvg();
    }
  }, [dispatch, isCellsDataChanged, explorer3dId]);

  const toggleExpandPopup = useCallback(
    e => {
      e.stopPropagation();

      dispatch({
        type: actionTypes.SET_IS_POPUP_EXPANDED,
      });
    },
    [dispatch]
  );

  return (
    <Modal
      show={opened}
      onHide={closeEditor}
      className="editor-modal"
      fullscreen="true"
    >
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>Edit CSV</Modal.Title>
          <div className="editor-modal-buttons">
            <Button
              variant="secondary"
              onClick={toggleExpandPopup}
              size="sm"
              className="button-secondary mr-4 d-flex align-items-center gap-1"
              id="expandBtn"
            >
              <span>{isPopupExpanded ? 'Collapse' : 'Expand'} image</span>
            </Button>
            <Button
              variant="secondary"
              onClick={handleClickRecognizeSVG}
              disabled={isDataCSVLoading}
              size="sm"
              className="button-secondary mr-4"
            >
              Recognize Unit numbers
            </Button>
            <Button
              variant="primary"
              onClick={handleClickSaveChanges}
              disabled={!isCellsDataChanged || isDataCSVLoading}
              size="sm"
              className="button-primary"
            >
              Save changes
            </Button>
            <Button
              onClick={handleClickCloseTable}
              size="sm"
              variant="danger"
              disabled={isDataCSVLoading}
            >
              Close
            </Button>
          </div>
        </Modal.Header>

        {isDataCSVLoading ? (
          <div className="editor-modal-loading">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <Tabs
              id="modal-tab"
              activeKey={modalTab}
              onSelect={handleClickModalTab}
              className="editor-tabs"
            >
              <Tab
                eventKey={DATA_PROP_ENUM.Units}
                title={DATA_PROP_ENUM.Units}
                mountOnEnter
              >
                {tablesData?.[DATA_PROP_ENUM.Units] && (
                  <EditorTable
                    data={tablesData}
                    dataPropName={DATA_PROP_ENUM.Units}
                    setIsCellsDataChanged={handleSetIsCellsDataChanged}
                    setTablesData={setTablesData}
                  />
                )}
              </Tab>
              <Tab
                eventKey={DATA_PROP_ENUM.FloorPlanTypes}
                title={DATA_PROP_ENUM.FloorPlanTypes}
                mountOnEnter
              >
                {tablesData?.[DATA_PROP_ENUM.FloorPlanTypes] && (
                  <EditorTable
                    data={tablesData}
                    dataPropName={DATA_PROP_ENUM.FloorPlanTypes}
                    setIsCellsDataChanged={handleSetIsCellsDataChanged}
                    setTablesData={setTablesData}
                  />
                )}
              </Tab>
            </Tabs>
            <Modal
              centered
              show={alertData.isShown}
              onHide={handleCloseAlert}
              backdrop="static"
              className="editor-modal-alert"
            >
              <Modal.Header closeButton>
                <p>{alertData.bodyText}</p>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  onClick={alertData.handleClickCloseBtn}
                  variant="danger"
                >
                  {alertData.closeBtnText}
                </Button>
                <Button onClick={handleCloseAlert}>No, continue editing</Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Modal.Dialog>
    </Modal>
  );
};

export default EditorCSV;

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AwsUrlsButtons from './AwsUrlsButtons';

const explorer3dBasePath = process.env.REACT_APP_EXPLORER3D_URL;

function AwsUrlsContainer({
  entityId,
  contentType,
  handleClick,
  handleDelete,
  isUnit,
  uploadPreviewId,
}) {
  const awsUrls = useSelector(state => state.explorer3d?.current?.awsUrls);
  const [items, setItems] = useState([]);
  const [isOpenAlerts, setIsOpenAlerts] = useState([]);

  useEffect(() => {
    if (awsUrls?.length) {
      setIsOpenAlerts(awsUrls.map(() => false));
    } else {
      setIsOpenAlerts([]);
    }
  }, [awsUrls, contentType]);

  useEffect(() => {
    setItems(awsUrls || []);
  }, [awsUrls, contentType]);

  async function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex === targetIndex) {
      return;
    }

    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);

    await fetch(`${explorer3dBasePath}/aws/drag-drop`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entityId,
        contentType,
        urls: nextState,
        isUnit,
      }),
    });
  }

  return (
    <>
      {!!items?.length && (
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={3}
            rowHeight={288}
            style={{ height: Math.ceil(items.length / 3) * 288 }}
          >
            {items.map((item, index) => {
              const fileFormat = item?.fileName?.split('.').pop().toLowerCase();
              const isImage = ['jpg', 'jpeg', 'png', 'webp', 'gif'].includes(fileFormat);

              return (
                <GridItem
                  key={`${item?.fileName}-${item?.lastModified}-${index}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {isImage ? (
                    <div
                      style={{
                        height: 'calc(100% - 16px)',
                        width: 'calc(100% - 16px)',
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${item.link})`,
                      }}
                    >
                      <AwsUrlsButtons
                        item={item}
                        index={index}
                        isOpenAlerts={isOpenAlerts}
                        setIsOpenAlerts={setIsOpenAlerts}
                        handleClick={handleClick}
                        handleDelete={handleDelete}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        height: 'calc(100% - 16px)',
                        width: 'calc(100% - 16px)',
                        position: 'relative',
                        backgroundSize: 'cover',
                      }}
                    >
                      <video
                        src={item.link}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          position: 'absolute',
                        }}
                      ></video>
                      <AwsUrlsButtons
                        item={item}
                        index={index}
                        isOpenAlerts={isOpenAlerts}
                        setIsOpenAlerts={setIsOpenAlerts}
                        handleClick={handleClick}
                        handleDelete={handleDelete}
                      />
                    </div>
                  )}
                </GridItem>
              );
            })}
          </GridDropZone>
        </GridContextProvider>
      )}
    </>
  );
}

export default AwsUrlsContainer;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from 'react-bootstrap';
import { setActiveTab } from 'redux/actions';

import DeleteIcon from 'mdi-react/DeleteIcon';
import PlayIcon from 'mdi-react/PlayIcon';

import IslandContainer from './IslandContainer';
import SwitchEditorTypeButton from 'containers/LocationSettings/components/SwitchEditorTypeButton';
import { TAB_ENUM } from './types';
import { useDeleteExplorerLocation } from '../Companies/components/LocationItem/hooks';

function Explorer3dHeader({ explorer3d, isFullScreen }) {
  const dispatch = useDispatch();

  const tab = useSelector(state => state.explorer3d.activeTab);

  function openInNewTab() {
    window.open(
      `${process.env.REACT_APP_WIDGET_URL}/overview/${explorer3d.settings.defaultTab}?explorer3dId=${explorer3d.id}`,
      '_blank'
    );
  }

  const deleteExplorerLocation = useDeleteExplorerLocation({
    locationId: explorer3d.id,
    goBack: true,
  });

  return (
    <IslandContainer
      isFullScreen={isFullScreen}
      color={isFullScreen ? 'secondary' : 'primary'}
      id="explorer3d-header"
      className="explorer3d-header"
    >
      <Container
        className="d-flex explorer3d-header-gap align-items-center"
        fluid={isFullScreen}
      >
        <h2 className="explorer3d-header-title">{explorer3d.name}</h2>

        <SwitchEditorTypeButton
          variant={tab === TAB_ENUM.SETTINGS ? 'success' : 'outline-success'}
          tooltip="Basic"
          clickHandler={() => {
            dispatch(setActiveTab(TAB_ENUM.SETTINGS));
          }}
        >
          Basic
        </SwitchEditorTypeButton>

        <SwitchEditorTypeButton
          variant={tab === TAB_ENUM.MARKERS ? 'success' : 'outline-success'}
          tooltip="Markers"
          clickHandler={() => {
            dispatch(setActiveTab(TAB_ENUM.MARKERS));
          }}
        >
          Markers
        </SwitchEditorTypeButton>
        <SwitchEditorTypeButton
          variant={tab === TAB_ENUM.CYCLES ? 'success' : 'outline-success'}
          tooltip="Cycles"
          clickHandler={() => {
            dispatch(setActiveTab(TAB_ENUM.CYCLES));
          }}
        >
          Cycles
        </SwitchEditorTypeButton>
        {explorer3d?.settings?.source && (
          <SwitchEditorTypeButton
            variant={tab === TAB_ENUM.FLOORS ? 'success' : 'outline-success'}
            tooltip="Sitemap"
            clickHandler={() => {
              dispatch(setActiveTab(TAB_ENUM.FLOORS));
            }}
          >
            Sitemap
          </SwitchEditorTypeButton>
        )}
        {explorer3d?.settings?.source && (
          <SwitchEditorTypeButton
            variant={
              tab === TAB_ENUM.CONTENT_EDITOR ? 'success' : 'outline-success'
            }
            tooltip="Content Editor"
            clickHandler={() => {
              dispatch(setActiveTab(TAB_ENUM.CONTENT_EDITOR));
            }}
          >
            Content Editor
          </SwitchEditorTypeButton>
        )}
        {explorer3d?.settings?.source && (
          <SwitchEditorTypeButton
            variant={
              tab === TAB_ENUM.PROPERTY_STRUCTURE ? 'success' : 'outline-success'
            }
            tooltip="Property structure"
            clickHandler={() => {
              dispatch(setActiveTab(TAB_ENUM.PROPERTY_STRUCTURE));
            }}
          >
            Property structure
          </SwitchEditorTypeButton>
        )}
        <SwitchEditorTypeButton
          variant={tab === TAB_ENUM.AMENITIES ? 'success' : 'outline-success'}
          tooltip="Amenities"
          clickHandler={() => {
            dispatch(setActiveTab(TAB_ENUM.AMENITIES));
          }}
        >
          Amenities
        </SwitchEditorTypeButton>
        <SwitchEditorTypeButton
          variant="outline-success"
          tooltip="Open explorer3d"
          clickHandler={openInNewTab}
        >
          <PlayIcon />
        </SwitchEditorTypeButton>
        <SwitchEditorTypeButton
          variant="outline-danger"
          tooltip="Delete explorer3d"
          clickHandler={deleteExplorerLocation}
        >
          <DeleteIcon />
        </SwitchEditorTypeButton>
        <Button
          variant="primary"
          type="submit"
          size="sm"
          className="explorer3d-header-btn"
        >
          Save settings
        </Button>
      </Container>
    </IslandContainer>
  );
}

export default Explorer3dHeader;

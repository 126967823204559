import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  loadExplorer3dById,
  setCurrentExplorer3d,
  createToast,
} from 'redux/actions';
import Explorer3d from './Explorer3d';
import {
  updateExplorer3dFull,
  updatePropertyDetails,
} from 'shared/utils/Explorer3dApi';
import { cloneDeep } from 'lodash';

function Explorer3dWrapper() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const explorer3d = useSelector(state => state.explorer3d?.current);

  const isLoading = useRef(false);

  useEffect(() => {
    dispatch(loadExplorer3dById(id));

    return () => {
      dispatch(setCurrentExplorer3d(null));
    };
  }, [id, dispatch]);

  async function submitHandler(data) {
    if (isLoading.current) {
      return;
    }

    isLoading.current = true;

    const propertyDetails = cloneDeep(explorer3d.propertyDetails);

    for (const b of propertyDetails) {
      for (const s of b.sections) {
        for (let i = 1; i <= Math.max(s.floors.length, s.floorTo); i++) {
          if (!s.floors.find(f => f.internalId === `${s.internalId}F${i}`)) {
            s.floors.push({
              internalId: `${s.internalId}F${i}`,
              markers: {},
              active: false,
            });
          } else {
            s.floors.find(f => f.internalId === `${s.internalId}F${i}`).active =
              false;
          }
        }

        for (let i = 1; i <= s.floors.length; i++) {
          if (i >= s.floorFrom && i <= s.floorTo) {
            s.floors.find(f => f.internalId === `${s.internalId}F${i}`).active =
              true;
          }
        }
      }
    }

    await Promise.all([
      updateExplorer3dFull({
        explorer3dId: id,
        name: data.name,
        settings: {
          ...data.settings,
        },
      }),
      updatePropertyDetails({
        explorer3dId: id,
        details: propertyDetails,
      }),
    ]);

    dispatch(createToast('SUCCESS', 'Successfully updated'));
    isLoading.current = false;
  }

  if (!explorer3d) return null;

  return <Explorer3d onSubmit={submitHandler} initialValues={explorer3d} />;
}

export default Explorer3dWrapper;

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import {
  CELL_TYPE_ENUM,
  DEFAULT_SORT_MODEL,
  UNITS_NON_EDITABLE_PROPS,
  UNITS_PROP_NAME_ENUM,
  SORT_BY,
  DATA_PROP_ENUM,
  FLOOR_PLAN_PROP_NAME_ENUM,
  FLOOR_PLAN_NON_EDITABLE_PROPS,
} from './consts';
import { CustomHeaderCell } from './CustomHeaderCell';
import {
  CURRENT_COLUMNS,
  FLOOR_PLAN_ROW_CELLS_TEMPLATE,
  UNITS_ROW_CELLS_TEMPLATE,
} from './utils';
import { SVGRenderer } from './SVGRenderer';
import { useSelector } from 'react-redux';

const EditorTable = ({
  data,
  dataPropName,
  setIsCellsDataChanged,
  setTablesData,
}) => {
  const [columns, setColumns] = useState(CURRENT_COLUMNS[dataPropName] ?? []);
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODEL);
  const propertySVGCoordinates = useSelector(
    state => state.editorCSV.propertySVGCoordinates
  );
  const floorSVGs = useSelector(state => state.editorCSV.floorSVGs);
  const [showPopupData, setShowPopupData] = useState(null);

  useEffect(() => {
    const onDocumentClick = e => {
      const clickedDataset = e.target.dataset;
      const externalUnitNumberColumnIdx = Object.values(
        UNITS_PROP_NAME_ENUM
      ).indexOf(UNITS_PROP_NAME_ENUM.ExternalUnitNumber);
      const internalUnitIdColumnIdx = Object.values(
        UNITS_PROP_NAME_ENUM
      ).indexOf(UNITS_PROP_NAME_ENUM.InternalUnitId);

      const path = e.composedPath();
      const clickedElement = path.find(el => el.id === 'expandBtn');

      // Check if `expandBtn` was clicked
      if (clickedElement) {
        return; // Exit if `expandBtn` was the target
      }

      if (
        showPopupData &&
        +clickedDataset.cellColidx !== externalUnitNumberColumnIdx &&
        +clickedDataset.cellColidx !== internalUnitIdColumnIdx &&
        !clickedDataset.externalId
      ) {
        setShowPopupData(null);
      }
    };

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [showPopupData]);

  const rows = useMemo(() => {
    let currentProps = UNITS_PROP_NAME_ENUM;
    let currentRowCellsTemplate = UNITS_ROW_CELLS_TEMPLATE;
    let currentNonEditableProps = UNITS_NON_EDITABLE_PROPS;

    if (dataPropName === DATA_PROP_ENUM.FloorPlanTypes) {
      currentProps = FLOOR_PLAN_PROP_NAME_ENUM;
      currentRowCellsTemplate = FLOOR_PLAN_ROW_CELLS_TEMPLATE;
      currentNonEditableProps = FLOOR_PLAN_NON_EDITABLE_PROPS;
    }

    const headerRow = {
      rowId: 'header',
      height: 40,
      cells: Object.values(currentProps).map(propName => {
        const className = ['header-cell'];

        if (dataPropName === DATA_PROP_ENUM.FloorPlanTypes) {
          className.push('header-cell-success');
        }

        if (propName === sortModel.column) {
          className.push('header-cell-sorted');

          if (sortModel.sort === SORT_BY.DESCENT) {
            className.push('header-cell-icon-reverted');
          }
        }

        return {
          type: CELL_TYPE_ENUM.HEADER,
          text: propName,
          className: className.join(' '),
        };
      }),
    };

    return [
      headerRow,
      ...data[dataPropName].map((unit, idx) => {
        const currentExternalId = unit[UNITS_PROP_NAME_ENUM.ExternalId];
        const currentFloorNumber = unit[UNITS_PROP_NAME_ENUM.FloorNumber];

        const cells = currentRowCellsTemplate.map(cell => {
          const currentCell = { ...cell };

          switch (cell.type) {
            case CELL_TYPE_ENUM.TEXT:
              currentCell.text = `${unit[cell.name] ?? ''}`;

              if (cell.name === UNITS_PROP_NAME_ENUM.ExternalId) {
                const coords =
                  propertySVGCoordinates[currentFloorNumber]?.[
                    currentExternalId
                  ];
                const svgContent = coords
                  ? floorSVGs[currentFloorNumber]
                  : null;

                currentCell.renderer = text => (
                  <>
                    <span
                      className="cell-renderer"
                      data-external-id={currentExternalId}
                    >
                      {text}
                    </span>
                    {showPopupData === text && (
                      <SVGRenderer
                        svgContent={svgContent}
                        coords={coords}
                        cellValue={currentExternalId}
                        floorNumber={currentFloorNumber}
                      />
                    )}
                  </>
                );
              }
              break;

            case CELL_TYPE_ENUM.NUMBER:
              if (cell.name === UNITS_PROP_NAME_ENUM.ExternalUnitNumber) {
                currentCell.format = new Intl.NumberFormat('en', {
                  useGrouping: false,
                });
              }
              currentCell.value = unit[cell.name];
              break;

            case CELL_TYPE_ENUM.CHECKBOX:
              currentCell.checked = unit[cell.name];
              break;

            case CELL_TYPE_ENUM.DATE:
              currentCell.date = unit[cell.name];
              break;

            default:
              break;
          }

          if (idx % 2 === 0) {
            currentCell.className = 'cell-dimmed';

            if (dataPropName === DATA_PROP_ENUM.FloorPlanTypes) {
              currentCell.className = 'cell-dimmed-success';
            }
          }

          if (currentNonEditableProps.includes(cell.name)) {
            currentCell.nonEditable = true;
          }

          return currentCell;
        });

        return {
          rowId: idx,
          cells,
          height: 30,
        };
      }),
    ];
  }, [
    dataPropName,
    data,
    showPopupData,
    sortModel.column,
    sortModel.sort,
    propertySVGCoordinates,
    floorSVGs,
  ]);

  const handleCellChanges = useCallback(
    changes => {
      setTablesData(prevTablesData => {
        const prevCellsData = prevTablesData[dataPropName];

        changes.forEach(change => {
          const rowIndex = change.rowId;
          const fieldName = change.columnId;

          console.log('prevCellsData[rowIndex]', prevCellsData[rowIndex]);

          const ALLOWED_FIELDS = new Set([
            UNITS_PROP_NAME_ENUM.BuildingId,
            UNITS_PROP_NAME_ENUM.SectionId,
            UNITS_PROP_NAME_ENUM.FloorNumber,
            UNITS_PROP_NAME_ENUM.ExternalUnitNumber,
          ]);

          const generateInternalUnitId = rowData => {
            const unitNumber = rowData[UNITS_PROP_NAME_ENUM.ExternalUnitNumber];
            const buildingId = rowData[UNITS_PROP_NAME_ENUM.BuildingId];
            const sectionId = rowData[UNITS_PROP_NAME_ENUM.SectionId];
            const floorNumber = rowData[UNITS_PROP_NAME_ENUM.FloorNumber];

            return `B${buildingId}S${sectionId}F${floorNumber}U${unitNumber}`;
          };

          if (change.newCell.text) {
            if (change.newCell.text && ALLOWED_FIELDS.has(fieldName)) {
              const rowData = prevCellsData[rowIndex];

              rowData[fieldName] = change.newCell.text;
              rowData[UNITS_PROP_NAME_ENUM.InternalUnitId] =
                generateInternalUnitId(rowData);
            }
          }

          switch (change.type) {
            case CELL_TYPE_ENUM.TEXT:
              prevCellsData[rowIndex][fieldName] = change.newCell.text;
              break;

            case CELL_TYPE_ENUM.NUMBER:
              prevCellsData[rowIndex][fieldName] = change.newCell.value;
              break;

            case CELL_TYPE_ENUM.CHECKBOX:
              prevCellsData[rowIndex][fieldName] = change.newCell.checked;
              break;

            case CELL_TYPE_ENUM.DATE:
              prevCellsData[rowIndex][fieldName] = change.newCell.date;
              break;

            default:
              break;
          }
        });

        setIsCellsDataChanged();

        const newData = {
          ...prevTablesData,
          [dataPropName]: prevCellsData,
        };

        return newData;
      });
    },
    [dataPropName, setIsCellsDataChanged, setTablesData]
  );

  const handleColumnResize = useCallback((ci, width) => {
    setColumns(prevColumns => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;

      return [...prevColumns];
    });
  }, []);

  useEffect(() => {
    if (sortModel.column) {
      setTablesData(prevTablesData => {
        const sortedItems = [...prevTablesData[dataPropName]].sort((a, b) => {
          const aValue = a[sortModel.column];
          const bValue = b[sortModel.column];

          if (
            (aValue == null || aValue === '') &&
            (bValue == null || bValue === '')
          )
            return 0;
          if (aValue == null || aValue === '') return 1;
          if (bValue == null || bValue === '') return -1;

          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return (bValue - aValue) * sortModel.sort;
          }

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortModel.sort === SORT_BY.ASCEND
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }

          return sortModel.sort === SORT_BY.ASCEND
            ? String(aValue).localeCompare(bValue)
            : String(bValue).localeCompare(aValue);
        });

        const newData = {
          ...prevTablesData,
          [dataPropName]: sortedItems,
        };

        return newData;
      });
    }
  }, [dataPropName, setTablesData, sortModel.column, sortModel.sort]);

  const handleHeaderCellClick = useCallback(columnName => {
    setSortModel(prevModel => {
      const newModel = { ...DEFAULT_SORT_MODEL, column: columnName };

      if (prevModel.column === columnName) {
        newModel.sort = -prevModel.sort;
      }

      return newModel;
    });
  }, []);

  const onFocusLocationChanged = useCallback(
    ({ rowId, columnId }) => {
      if (
        columnId === UNITS_PROP_NAME_ENUM.ExternalId ||
        columnId === UNITS_PROP_NAME_ENUM.InternalUnitId ||
        columnId === UNITS_PROP_NAME_ENUM.ExternalUnitNumber
      ) {
        const externalId =
          data[dataPropName][rowId]?.[UNITS_PROP_NAME_ENUM.ExternalId] ?? null;

        setShowPopupData(externalId);
      } else {
        setShowPopupData(null);
      }
    },
    [data, dataPropName]
  );

  return (
    <Container fluid className="px-3 editor-container">
      <Row>
        <Col className="h-100">
          <div
            className={
              dataPropName === DATA_PROP_ENUM.FloorPlanTypes
                ? 'editor-wrapper editor-wrapper-floor-plan'
                : 'editor-wrapper'
            }
          >
            <ReactGrid
              rows={rows}
              columns={columns}
              stickyTopRows={1}
              stickyLeftColumns={1}
              enableFillHandle
              enableRangeSelection
              onCellsChanged={handleCellChanges}
              onColumnResized={handleColumnResize}
              onFocusLocationChanged={onFocusLocationChanged}
              className={
                dataPropName === DATA_PROP_ENUM.FloorPlanTypes
                  ? 'editor-grid-floor-plan'
                  : ''
              }
              customCellTemplates={{
                [CELL_TYPE_ENUM.HEADER]: new CustomHeaderCell(
                  handleHeaderCellClick,
                  dataPropName
                ),
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(EditorTable);

import React, { useCallback, useState } from 'react';
import { Color } from '@tiger-ui/color-palette-generator';
import { Col, Container, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import IslandContainer from './IslandContainer';
import FileInput from './FileInput';
import { uploadLogo, uploadSelectables } from 'shared/utils/Explorer3dApi';
import { useDispatch, useSelector } from 'react-redux';
import renderField from '../../shared/components/form/Field';
import renderCheckbox from '../../shared/components/form/Checkbox';
import { updateExplorer3dSettings, createToast } from 'redux/actions';
import ColorPicker from 'shared/components/form/ColorPicker';
import ControlledSelect from './ControlledSelect';
import { uploadFilesToAws } from 'redux/actions';

const defaultOptions = [
  { name: '3DOverview', value: 'building' },
  { name: 'ApartmentsOnPlan', value: 'plan' },
  { name: 'Amenities', value: 'tour' },
  { name: 'Neighborhood', value: 'neighbourhood' },
];

function BaseSettings() {
  const dispatch = useDispatch();

  const explorer3d = useSelector(state => state.explorer3d.current);
  const [defaultTab, setDefaultTab] = useState(defaultOptions[0].value);

  const settings = useSelector(state => state.explorer3d.current.settings);

  async function uploadLogoHandler(files) {
    if (!Array.from(files).length) {
      dispatch(createToast('ERROR', 'File for logo is not attached'));

      return;
    }
    try {
      await uploadLogo({
        settingId: explorer3d.id,
        file: Array.from(files)[0],
      });
      dispatch(createToast('SUCCESS', 'Logo uploaded'));
    } catch (error) {
      dispatch(createToast('ERROR', 'Unknown error'));
    }
  }

  async function uploadSelectablesHandler(files) {
    if (!files || !Array.from(files).length) {
      dispatch(createToast('ERROR', 'Selectables are not attached'));
      return;
    }

    try {
      const glbFile = Array.from(files).map(file => {
        const newFileName = 'selectables.glb';

        return new File([file], newFileName, { type: file.type });
      });

      const bucketName = 'seenic-dev-content';
      const folderName = `locations/${explorer3d.id}/selectables`;
      try {
        await dispatch(uploadFilesToAws(glbFile, bucketName, folderName));
      } catch (error) {
        dispatch(createToast('ERROR', 'Unknown error'));
      }

      dispatch(createToast('SUCCESS', 'Selectables uploaded'));
    } catch (error) {
      console.error('Upload error:', error);
      dispatch(createToast('ERROR', 'Unknown error'));
    }
  }

  const onChangeMainColorPicker = useCallback(
    color => {
      const lightColor = new Color(color, {
        darkColorIntensity: 35,
        lightColorIntensity: 35,
      }).light;

      const darkColor = new Color(color, {
        darkColorIntensity: 35,
        lightColorIntensity: 35,
      }).dark;

      dispatch(
        updateExplorer3dSettings({
          ...settings,
          mainColor: color,
          lightColor,
          darkColor,
        })
      );
    },
    [dispatch, settings]
  );

  const onChangeSecondaryColorPicker = useCallback(
    color => {
      const lightColor = new Color(color, {
        darkColorIntensity: 35,
        lightColorIntensity: 35,
      }).light;

      dispatch(
        updateExplorer3dSettings({
          ...settings,
          secondaryColor: color,
          secondaryLightColor: lightColor,
        })
      );
    },
    [dispatch, settings]
  );

  const onChangeMeshColorPicker = useCallback(
    color => {
      dispatch(
        updateExplorer3dSettings({
          ...settings,
          meshColor: color,
        })
      );
    },
    [dispatch, settings]
  );

  const onChangeDefaultTab = useCallback(
    defaultTab => {
      dispatch(
        updateExplorer3dSettings({
          ...settings,
          defaultTab,
        })
      );
      setDefaultTab(defaultTab);
    },
    [dispatch, settings]
  );

  return (
    <IslandContainer>
      <h2 className="explorer3d-item-title">Base settings</h2>
      <Field
        name="name"
        type="text"
        label="Name"
        component={renderField}
        initialValue={settings.name ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateExplorer3dSettings({
              ...settings,
              name: val,
            })
          );
        }}
      />
      <Field
        name="settings['overviewLocation']"
        type="number"
        label="3d Overview Location ID"
        component={renderField}
        initialValue={settings.overviewLocation ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateExplorer3dSettings({
              ...settings,
              overviewLocation: val,
            })
          );
        }}
      />
      <ColorPicker
        fieldName="mainColor"
        label="Main color"
        onChangeColorPicker={onChangeMainColorPicker}
        initialValue={settings.mainColor ?? ''}
      />
      <ColorPicker
        fieldName="secondaryColor"
        label="Secondary color"
        onChangeColorPicker={onChangeSecondaryColorPicker}
        initialValue={settings.secondaryColor ?? ''}
      />
      <ColorPicker
        fieldName="meshColor"
        label="Mesh color"
        onChangeColorPicker={onChangeMeshColorPicker}
        initialValue={settings.meshColor ?? ''}
      />
      <Row className="mb-3">
        <Col sm={3} className="d-flex align-items-center">
          Default tab
        </Col>
        <Col sm={9} className="d-flex align-items-center">
          <Container className="p-0">
            <ControlledSelect
              options={defaultOptions}
              value={defaultTab}
              onChange={onChangeDefaultTab}
              className="col-sm-12"
            />
          </Container>
        </Col>
      </Row>
      <Field
        name="settings['hideArrows']"
        type="checkbox"
        label="Hide arrows"
        component={renderCheckbox}
      />
      <Field
        name="settings['hidePrices']"
        type="checkbox"
        label="Hide prices"
        component={renderCheckbox}
      />
      <FileInput
        buttonLabel="Upload Logo"
        onSubmit={uploadLogoHandler}
        id={'file'}
      />
      <FileInput
        buttonLabel="Upload Selectables"
        onSubmit={file => uploadSelectablesHandler(file)}
        id={'selectable'}
      />
    </IslandContainer>
  );
}

export default BaseSettings;

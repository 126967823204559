import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { Button, Container, Row } from 'react-bootstrap';

import renderField from '../../shared/components/form/Field';

import MapSettingsPopup from './MapSettingsPopup';
import { useDispatch, useSelector } from 'react-redux';
import { updateExplorer3dSettings } from '../../redux/actions';
import { DEFAULT_CENTER_LAT, DEFAULT_CENTER_LNG } from '../../constants';

function Neighborhood() {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const settings = useSelector(state => state.explorer3d.current.settings);

  const hidePopup = () => {
    setIsOpenPopup(false);
  };

  const openPopup = () => {
    setIsOpenPopup(true);
  };

  useEffect(() => {
    if (!settings.centerPoint) {
      dispatch(
        updateExplorer3dSettings({
          centerPoint: [DEFAULT_CENTER_LAT, DEFAULT_CENTER_LNG],
        })
      );
    }
  }, [dispatch, settings.centerPoint]);

  return (
    <Container className="mt-1">
      <h2 className="explorer3d-item-title">Neighborhood</h2>
      <Field
        name="settings['mapNorth']"
        type="number"
        min={0}
        tooltip={
          <>
            <div>Degrees: 0-360</div>
            <div>Direction: Clockwise from top</div>
            <div>Default: 0 (Top)</div>
          </>
        }
        label="Map north"
        component={renderField}
        initialValue={settings.mapNorth ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateExplorer3dSettings({
              ...settings,
              mapNorth: val,
            })
          );
        }}
      />
      <Field
        name="settings['fillOpacity']"
        type="text"
        label="Highlight opacity"
        component={renderField}
        tooltip={
          <>
            <div>Property highlight opacity</div>
            <div>Range: 0-1</div>
            <div>Default: 0.5</div>
          </>
        }
        initialValue={settings.fillOpacity ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateExplorer3dSettings({
              ...settings,
              fillOpacity: val,
            })
          );
        }}
      />
      <Field
        name="settings['strokeWidth']"
        type="text"
        label="Highlight width"
        component={renderField}
        tooltip={
          <>
            <div>Property highlight width</div>
            <div>Default: 2</div>
          </>
        }
        initialValue={settings.strokeWidth ?? ''}
        onUpdateStore={val => {
          dispatch(
            updateExplorer3dSettings({
              ...settings,
              strokeWidth: val,
            })
          );
        }}
      />
      <Row>
        <Container className="p-3">
          <Button onClick={openPopup}>Map setup</Button>
        </Container>
      </Row>
      {isOpenPopup && (
        <MapSettingsPopup closeEditor={hidePopup} opened={isOpenPopup} />
      )}
    </Container>
  );
}

export default Neighborhood;

import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
  uploadFloorPlan,
  deleteFloorPlan,
  renameFloorPlan,
} from 'shared/utils/Explorer3dApi';
import { isNil } from 'ramda';
import { updateExplorer3dSettings, createToast } from 'redux/actions';
import { extname } from './utils';
import { FLOOR_STATUS } from './types';
import { Button } from 'react-bootstrap';

const acceptStyle = {
  backgroundColor: '#D9EAFF',
};

function FloorItem({
  status,
  progress,
  isSelected,
  number: initialNumber,
  onClick,
  setActiveItem,
}) {
  const ref = useRef();
  const dispatch = useDispatch();

  const explorer3d = useSelector(state => state.explorer3d.current);

  const floorPlans = useSelector(
    state => state.explorer3d.current.settings.floorPlans
  );
  const floorPlan = floorPlans[initialNumber];

  const [innerProgress, setInnerProgress] = useState(null);
  const [number, setNumber] = useState(initialNumber);
  const [initNumber, setInitNumber] = useState(initialNumber);

  const debounceTimeout = useRef(null); // Ref to store the debounce timer

  const onDrop = useCallback(
    async files => {
      const res = await uploadFloorPlan({
        explorer3dId: explorer3d.id,
        number,
        file: files[0],
        onProgress: p => {
          setInnerProgress(p);
        },
      });

      dispatch(updateExplorer3dSettings({ floorPlans: res.floorPlans }));
      setInnerProgress(null);
    },
    [dispatch, number, explorer3d.id]
  );

  const handleDelete = useCallback(async () => {
    const res = await deleteFloorPlan({
      explorer3dId: explorer3d.id,
      number,
    });

    dispatch(updateExplorer3dSettings({ floorPlans: res.floorPlans }));
    dispatch(createToast('SUCCESS', 'Floor map removed'));
  }, [dispatch, number, explorer3d.id]);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    noClick: true,
    noDrag: status === FLOOR_STATUS.DOWNLOADED,
  });

  const style = useMemo(
    () => ({
      opacity: status === FLOOR_STATUS.DOWNLOADED ? 1 : 0.6,
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isDragAccept, status]
  );

  const name = floorPlan
    ? `floor${number}${extname(floorPlan)}`
    : 'photo not attached';

  const actualProgress = progress || innerProgress;

  let width = 0;

  if (isSelected && status === FLOOR_STATUS.DOWNLOADED) {
    width = '100%';
  }

  width = !isNil(actualProgress)
    ? ref.current.getBoundingClientRect().width * actualProgress
    : width;

  const handleChange = async e => {
    const value = e.target.value;
    setNumber(value); // Update the state immediately

    // Debounce console.log of initNumber
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {

      try {
        const res = await renameFloorPlan({
          explorer3dId: explorer3d.id,
          number: value,
          initNumber,
        });
        setInitNumber(value);
        setActiveItem(value);
        dispatch(updateExplorer3dSettings({ floorPlans: res.floorPlans }));
        dispatch(createToast('SUCCESS', 'Floor renamed successfully'));
      } catch (error) {
        console.error('Error renaming floor plan:', error);
        dispatch(createToast('ERROR', 'Failed to rename floor'));
      }
    }, 500);
  };

  return (
    <div className="floor-item" {...getRootProps({ style })}>
      <div ref={ref} onClick={onClick}>
        <div
          className="progress"
          style={{
            width,
          }}
        />
        {isSelected && <div className="active-marker" />}
        <div className="floor-item-container">
          <input {...getInputProps()} />
          <div className="text-container">
            <div className="index">
              <input
                onChange={handleChange} // Call handleChange on input change
                className="indexInput"
                type="text"
                value={number} // Bind the input value to the state
              />
            </div>{' '}
            <p className="main-text">{name}</p>
          </div>
          {floorPlan && (
            <div className="d-flex align-items-end justify-content-center h-100">
              <Button
                className={`delete-button d-flex align-items-center justify-content-center ${isSelected ? 'item-selected' : ''} h-100`}
                onClick={handleDelete}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/trash.svg`}
                  alt="trash"
                />
              </Button>
            </div>
          )}

          {status !== FLOOR_STATUS.DOWNLOADED && (
            <img
              src={`${process.env.PUBLIC_URL}/img/dot.svg`}
              className="icon"
              alt="dot icon"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FloorItem;

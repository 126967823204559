import React, { useState } from 'react';
import { Row, Form, Col, Dropdown } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="ml-3 mr-3 mt-2 d-flex justify-content-between"
  >
    {children}
  </div>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={{ ...style, maxHeight: '200px', overflowY: 'auto' }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children)
            .filter(
              (child) =>
                !value ||
                child.props.children.toLowerCase().includes(value.toLowerCase())
            )
            .map((child) =>
              React.cloneElement(child, { onClick: child.props.onClick })
            )}
        </ul>
      </div>
    );
  }
);

function SelectWithSearch({
  input,
  label,
  meta: { touched, error },
  options,
  onChange,
}) {
  const selectedOption = options.find((option) => option.value === input.value);

  return (
    <Form.Group as={Row}>
      {label && (
        <Form.Label column sm={3}>
          {label}
        </Form.Label>
      )}
      <Col sm={9}>
        <Dropdown
          className="square border border-1 rounded"
          style={{ height: '40px' }}
        >
          <Dropdown.Toggle as={CustomToggle}>
            <div>{selectedOption ? selectedOption.name : 'Select an option'}</div>
            <div> &#x25bc;</div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            {options.map(({ name, value, key }) => (
              <Dropdown.Item
                key={key}
                onClick={() => {
                  input.onChange(value);
                  if (onChange) {
                    onChange(value); // Call the onChange prop
                  }
                }}
                className="dropdown-item"
              >
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {touched && error && <span className="text-danger">{error}</span>}
      </Col>
    </Form.Group>
  );
}

export default SelectWithSearch;
